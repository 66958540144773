import React, { useState, useEffect, useRef, Fragment, forwardRef } from 'react'
import { useDebounce } from 'use-debounce'
import { setMinimumPrecision } from './helpers'
import './style'

const AddressAutocomplete = ({
  model,
  address,
  timezone,
  geographicDistribution = null,
  endpoint,
  horizontal,
  required = false,
  allowManualCoordinates = false,
  mailingAddress = false
}) => {
  const COORDINATE_PRECISION = 5
  const COORDINATE_PATTERN = /^-?\d+.\d+\s*,\s*-?\d+.\d+$/

  const { address_line1, address_line2, city, state, zip, manual_coordinates, latitude, longitude } = address
  const preSelectedValues = {
    address1: address_line1 || '',
    address2: address_line2 || '',
    city: city || '',
    state: state || '',
    zip: zip || '',
    manualCoordinates: manual_coordinates,
    latitude: setMinimumPrecision(latitude || '', COORDINATE_PRECISION),
    longitude: setMinimumPrecision(longitude || '', COORDINATE_PRECISION)
  }
  const [values, setValues] = useState(preSelectedValues)
  const [debouncedAddress] = useDebounce(values, 300)
  const [suggestions, setSuggestions] = useState([])
  const [displaySuggestions, setDisplaySuggestions] = useState(false)
  const isInitialMount = useRef(true)
  const addressOneWrapperRef = useRef()

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false
      // only make requests to smarty after initial render, when address1 contains 1+ alphanumeric characters
    } else if (debouncedAddress.address1 && /[a-zA-Z0-9]/g.test(debouncedAddress.address1) && !isInitialMount.current) {
      getSuggestions()
    }
  }, [debouncedAddress.address1])

  // make request to smarty through app/controllers/smarty_controller.rb
  const fetchSuggestions = async address1 => {
    const response = await fetch(`${endpoint}?q=${address1}`)
    if (response.ok) {
      const json = await response.json()
      return JSON.parse(json.body)
    }
  }

  useEffect(() => {
    const closeMenu = e => {
      if (addressOneWrapperRef.current && displaySuggestions && !addressOneWrapperRef.current.contains(e.target)) {
        setDisplaySuggestions(false)
      }
    }

    if (displaySuggestions) {
      document.addEventListener('mousedown', closeMenu)
    } else {
      document.removeEventListener('mousedown', closeMenu)
    }

    return () => {
      document.removeEventListener('mousedown', closeMenu)
    }
  }, [displaySuggestions])

  // use smarty response to update state and render suggestions
  const getSuggestions = async () => {
    let response = await fetchSuggestions(values.address1)

    let cleanedSuggestions

    if (response.suggestions) {
      cleanedSuggestions = response.suggestions.map(suggestion => {
        return {
          address1: suggestion.street_line,
          address2: suggestion.secondary,
          city: suggestion.city,
          state: suggestion.state,
          zip: suggestion.zipcode
        }
      })
    }

    if (cleanedSuggestions) {
      setSuggestions(cleanedSuggestions)
    }
  }

  // when user types in any input field
  const handleChange = event => {
    setValues({ ...values, [event.target.id]: event.target.value })
  }

  const handleManualCoordinatesChanges = () => {
    setValues({ ...values, manualCoordinates: !values.manualCoordinates })
  }

  const changeAddressOne = event => {
    handleChange(event)
    setDisplaySuggestions(event.target.value !== '')
  }

  // when user clicks on an address from the suggestions dropdown
  const selectAddressMenuItem = suggestion => {
    // clearing out timezone, geographic distribution, neighborhood, and county to avoid showing stale data
    if (document.getElementById('timezone')) {
      document.getElementById('timezone').innerHTML = ''
    }
    if (document.getElementById('geographicDistribution')) {
      document.getElementById('geographicDistribution').innerHTML = ''
    }

    let selector = mailingAddress ? '[id$=mailing_address_attributes_' : '[id$=address_attributes_'
    let neighborhoodElement = document.querySelector(`${selector}neighborhood]`)
    let countyElement = document.querySelector(`${selector}county]`)

    if (neighborhoodElement) {
      neighborhoodElement.value = ''
    }
    if (countyElement) {
      countyElement.value = ''
    }

    setValues({
      ...values,
      address1: suggestion.address1,
      address2: suggestion.address2,
      city: suggestion.city,
      state: suggestion.state,
      zip: suggestion.zip
    })
    setDisplaySuggestions(false)
  }

  const setCoordinatePrecision = event => {
    const value = event.target.value
    setValues({ ...values, [event.target.id]: setMinimumPrecision(value, COORDINATE_PRECISION) })
  }

  const onPasteCoordinates = event => {
    const clipboard = event.clipboardData.getData('text')
    if (COORDINATE_PATTERN.test(clipboard)) {
      const coordinates = clipboard.split(',')
      const lat = setMinimumPrecision(coordinates[0].trim(), COORDINATE_PRECISION)
      const long = setMinimumPrecision(coordinates[1].trim(), COORDINATE_PRECISION)

      setValues({ ...values, latitude: lat, longitude: long })
      event.preventDefault()
    }
  }

  const getFieldName = fieldName => {
    return mailingAddress
      ? `${model}[mailing_address_attributes][${fieldName}]`
      : `${model}[address_attributes][${fieldName}]`
  }

  return (
    <Fragment>
      <HiddenInput model={model} fieldName='validate_minimal_address_data' mailingAddress={mailingAddress} />
      <HiddenInput model={model} fieldName='geocoding_fall_back_to_zip' mailingAddress={mailingAddress} />

      <InputWrapper label='Address line 1' horizontal={horizontal} ref={addressOneWrapperRef} id='address1'>
        <InputField
          id='address1'
          name={getFieldName('address_line1')}
          value={values.address1}
          onChange={e => changeAddressOne(e)}
          required={required}
        />
        {displaySuggestions && suggestions.length > 0 && (
          <div className='position-relative w-100'>
            <div className='suggestions-dropdown d-flex flex-column position-absolute py-2'>
              <SuggestionMenu suggestions={suggestions} onClick={selectAddressMenuItem} />
            </div>
          </div>
        )}
      </InputWrapper>

      <InputWrapper label='Address line 2' horizontal={horizontal} id='address2'>
        <InputField
          id='address2'
          name={getFieldName('address_line2')}
          value={values.address2}
          onChange={e => handleChange(e)}
        />
      </InputWrapper>

      <InputWrapper label='City' horizontal={horizontal} id='city'>
        <InputField
          id='city'
          name={getFieldName('city')}
          value={values.city}
          onChange={e => handleChange(e)}
          required={required}
        />
        {timezone && (
          <small className='form-text text-muted' id='timezone'>
            {timezone}
          </small>
        )}
      </InputWrapper>

      <div className='row'>
        <div className={`${horizontal ? 'col-12 col-sm-6' : 'col-12'}`}>
          <InputWrapper label='State' horizontal={horizontal} id='state'>
            <InputField
              id='state'
              name={getFieldName('state')}
              value={values.state}
              onChange={e => handleChange(e)}
              required={required}
            />
          </InputWrapper>
        </div>

        <div className={`${horizontal ? 'col-12 col-sm-6' : 'col-12'}`}>
          <InputWrapper label='Zip Code' horizontal={horizontal} id='zip'>
            <InputField
              id='zip'
              name={getFieldName('zip')}
              value={values.zip}
              onChange={e => handleChange(e)}
              required={required}
            />
            {geographicDistribution && (
              <small className='form-text text-muted' id='geographicDistribution'>
                {geographicDistribution}
              </small>
            )}
          </InputWrapper>
        </div>
        {allowManualCoordinates && !mailingAddress && (
          <>
            <div className={`${horizontal ? 'col-12 col-sm-6' : 'col-12'}`}>
              <InputWrapper label='Manual Coordinates' horizontal={horizontal} id='manualCoordinates' flex>
                <input
                  type='checkbox'
                  id='manualCoordinates'
                  checked={values.manualCoordinates}
                  onChange={e => handleManualCoordinatesChanges(e)}
                  required={required}
                />
                <input
                  type='hidden'
                  name={getFieldName('manual_coordinates')}
                  value={values.manualCoordinates ? 1 : 0}
                />
              </InputWrapper>
            </div>

            {values.manualCoordinates && (
              <>
                <div className={`${horizontal ? 'col-12 col-sm-6' : 'col-12'}`}>
                  <InputWrapper label='Latitude' horizontal={horizontal} id='latitude'>
                    <InputField
                      id='latitude'
                      name={getFieldName('latitude')}
                      value={values.latitude}
                      onChange={e => handleChange(e)}
                      onBlur={e => setCoordinatePrecision(e)}
                      onPaste={e => onPasteCoordinates(e)}
                    />
                  </InputWrapper>
                </div>

                <div className={`${horizontal ? 'col-12 col-sm-6' : 'col-12'}`}>
                  <InputWrapper label='Longitude' horizontal={horizontal} id='longitude'>
                    <InputField
                      id='longitude'
                      name={getFieldName('longitude')}
                      value={values.longitude}
                      onChange={e => handleChange(e)}
                      onBlur={e => setCoordinatePrecision(e)}
                      onPaste={e => onPasteCoordinates(e)}
                    />
                  </InputWrapper>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </Fragment>
  )
}

const HiddenInput = ({ model, fieldName, mailingAddress }) => (
  <input
    type='hidden'
    value='true'
    name={
      mailingAddress
        ? `${model}[mailing_address_attributes][${fieldName}]`
        : `${model}[address_attributes][${fieldName}]`
    }
    id={
      mailingAddress ? `${model}_mailing_address_attributes_${fieldName}` : `${model}_address_attributes_${fieldName}`
    }
  />
)

const InputWrapper = forwardRef(({ children, label, horizontal, id, flex = false }, ref) => (
  <div className='form-group' ref={ref}>
    <div className={`${!horizontal && 'row'}`}>
      <label className={`${horizontal ? 'form-label' : 'col col-form-label'}`} htmlFor={id}>
        {label}
      </label>
      <div className={`${!horizontal && `col-9 col-sm-8 ${flex && 'd-flex'}`}`}>{children}</div>
    </div>
  </div>
))

const InputField = ({ required = false, type = 'text', ...rest }) => (
  <input
    type={type}
    required={required}
    className='form-control'
    autoComplete='none' //invalid value to turn off auto complete in browser
    {...rest}
  />
)

const SuggestionMenu = ({ suggestions, onClick }) => {
  return suggestions.map((suggestion, idx) => {
    return (
      <div key={idx} className='suggestion-item' onClick={() => onClick(suggestion)}>
        {suggestion.address1} {suggestion.address2} {suggestion.city}, {suggestion.state} {suggestion.zip}
      </div>
    )
  })
}

export default AddressAutocomplete
